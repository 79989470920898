import React, {useState} from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import Fade from 'react-reveal/Fade'
import CountUp from 'react-countup'

import parseFollowersCount from "./helpers/parseFollowersCount"
import parseViewsCount from "./helpers/parseViewsCount"

import Section from '../../../../components/section/Section'
import Parallax from '../../../../components/parallax/Parallax'
// import StyledBlock from '../../../../components/styled-block/StyledBlock'

import style from './Bio.module.scss'

const Bio = () => {
  const [countupReady, setCountupReady] = useState(false)

  const statsData = useStaticQuery(
    graphql`
      query {
        allStreamStats {
          nodes {
            followersCount,
            viewsCount
          }
        }
      }
    `
  ).allStreamStats?.nodes[0]

  const followersCount = JSON.parse(parseFollowersCount(statsData.followersCount))
  const viewsCount = JSON.parse(parseViewsCount(statsData.viewsCount))

  return (
    <Section className={style.bio}>
      <Fade bottom distance="25%">
        <div className={style.title}>
          <h2>Trying to fix sleep schedule since 2013</h2>
        </div>
      </Fade>
      <div className={style.content}>
        {/* <Fade bottom distance="25%">
          <StyledBlock className={style.media}>
            <div className={style.image}>
              <img src="./assets/images/bio2.jpg" alt="bio" />
            </div>
          </StyledBlock>
        </Fade> */}
        <div className={style.media}>
          <Fade bottom distance="25%">
            {/* <Parallax
              className={style.image}
              offset={20}
            > */}
            <div className={style.image}>
              <img src="./assets/images/bio.jpg" alt="bio" />
            </div>
            {/* </Parallax> */}
          </Fade>
        </div>
        <Fade bottom distance="25%">
          <div className={style.text}>
            <p>Ming is a UK-based Twitch streamer, best known for playing FPS games and roleplay mods such as ARMA&nbsp;3, Grand Theft Auto V, PUBG, Valorant and others.</p>
            <p>In the community he is recognized for his accurate and&nbsp;precise aim, exceptional driving skills and out-of-the-box thinking chase escapes.</p>
            <Fade bottom distance="25%" onReveal={() => setCountupReady(true)}>
              <div className={style.stats}>
                <div className={style.item}>
                  <span>
                    <CountUp
                      start={countupReady && followersCount.start}
                      end={followersCount.end}
                      duration={5}
                    />
                    K+
                  </span>
                  <span className={style.stat}>Followers</span>
                </div>
                <div className={style.item}>
                  <span>
                    <CountUp
                      start={countupReady && viewsCount.start}
                      end={viewsCount.end}
                      duration={5}
                      decimals={2}
                    />
                    M+
                  </span>
                  <span className={style.stat}>Views</span>
                </div>
              </div>
            </Fade>
          </div>
        </Fade>
      </div>
    </Section>
  )
}

export default Bio
